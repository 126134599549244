import React from 'react';

import Logo from '@components/Logo/Logo';

import './Header.scss';

const Header = () => {
  return (
    <header className="finsa-header">
      <Logo />
    </header>
  );
};

export default Header;
