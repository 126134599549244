import React from 'react';

const Item = ({ children }) => <tr><td>{children}</td></tr>;

const MyEmail = ({
  name,
  surname,
  position,
  email,
  identity,
  location,
  supervision,
  ombudsman,
  gdpr,
  gdproblige,
  financialService,
  products,
  targets,
  salutation,
  company,
  agrees,
}) => {
  return (
    <table width="600" align="center" cellpadding="0" cellspacing="0" border="0" valign="top">
      <tbody>
        <Item>
          <strong>From:</strong>
          <br />
          <em>{email}</em>
          <br />
          <br />
        </Item>
        <Item>
          <strong>Salutation:</strong>
          <br />
          <em>{salutation}</em>
          <br />
          <br />
        </Item>
        <Item>
          <strong>Name:</strong>
          <br />
          <em>{name}</em>
          <br />
          <br />
        </Item>
        <Item>
          <strong>Surname:</strong>
          <br />
          <em>{surname}</em>
          <br />
          <br />
        </Item>
        <Item>
          <strong>Position:</strong>
          <br />
          <em>{position}</em>
          <br />
          <br />
        </Item>
        <Item>
          <strong>Company:</strong>
          <br />
          <em>{company}</em>
          <br />
          <br />
        </Item>
        <Item>
          <strong>Who are you?</strong>
          <br />
          <em>{identity}</em>
          <br />
          <br />
        </Item>
        <Item>
          <strong>Where Are You From?</strong>
          <br />
          <em>{location}</em>
          <br />
          <br />
        </Item>
        <Item>
          <strong>Are You Supervised In Your Home Country?</strong>
          <br />
          <em>{supervision}</em>
          <br />
          <br />
        </Item>
        <Item>
          <strong>
            Are You Already Affiliated To A Swiss Ombudsman?
          </strong>
          <br />
          <em>{ombudsman}</em>
          <br />
          <br />
        </Item>
        <Item>
          <strong>
            As you may know, the EU GDPR obliges all companies outside the EU to designate a representative in the EU if they process personal data of EU residents and do not maintain an establishment in the EU. In the view of the above, do you fall under the scope of this obligation?
          </strong>
          <br />
          <em>{gdproblige ? gdproblige : '/'}</em>
          <br />
          <br />
        </Item>
        <Item>
          <strong>
            Have You Designated In Writing A Representative In The European Union For The
            Gdpr?
          </strong>
          <br />
          <em>{gdpr ? gdpr : '/'}</em>
          <br />
          <br />
        </Item>
        <Item>
          <strong>
            Do You Offer Financial Services Within The Meaning Of Art. 3 Finsa Or
            Do You Do Execution Only Or Offer Only Towards Swiss Supervised
            Intermediaries?
          </strong>
          <br />
          <em>{financialService}</em>
          <br />
          <br />
        </Item>
        <Item>
          <strong>What Kind Of Products Do You Offer?</strong>
          <br />
          <ul>
            {products.map((item, index) => {
              return (
                <li key={index}>
                  <em>{item}</em>
                </li>
              );
            })}
          </ul>
        </Item>
        <Item>
          <strong>
            What kind of investors do you plan to target in switzerland? (several
            answers possible, please focus on the end investors)
          </strong>
          <br />
          <ul>
            {targets.map((item, index) => {
              return (
                <li key={index}>
                  <em>{item}</em>
                </li>
              );
            })}
          </ul>
        </Item>
        <Item>
          <strong>GDPR-consent</strong>
          <br />
          <ul>
            {agrees.map((item, index) => {
              return (
                <li key={index}>
                  <em>{item}</em>
                </li>
              );
            })}
          </ul>
        </Item>
      </tbody>
    </table>
  );
};

export default MyEmail;
