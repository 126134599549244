import React, { useContext, useEffect } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { ContactFormContext } from '../ContactFormContext';

import Form from '@ui/Form/Form';
import Input from '@ui/Input/Input';

import './FinsaStep.scss';

const LocationStep = ({ location }) => {

  useEffect(() => {
    scrollTo('#form');
  }, []);

  const { dispatch } = useContext(ContactFormContext);

  const availableLocations = ['Switzerland', 'European Union', 'Other'];

  return (
    <div className="finsa-step">
      <h2 className="finsa-step__headline">Where are you from?</h2>
      <Form>
        {availableLocations.map(id => (
          <Input
            label={id}
            key={id}
            type="radio"
            id={id}
            name="location"
            value={id}
            checked={location === id}
            onChange={e =>
              dispatch({ type: 'LOCATION_CHANGE', payload: e.target.value })
            }
          />
        ))}
      </Form>
    </div>
  );
};

export default LocationStep;
