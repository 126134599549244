import React from 'react';

import './Button.scss';

const button = props => (
  <button
    type={props.type || 'submit'}
    onClick={props.click}
    className="finsa-button"
    disabled={props.disabled}
  >
    {props.text}
  </button>
);

export default button;
