import React from 'react';

import './default.scss';

import Header from '@components/Header/Header';
import Footer from '@components/Footer/Footer';

const layout = ({ children }) => (
  <div className="finsa-wrapper">
    <Header />
    <main className="finsa-main">{children}</main>
    <Footer />
  </div>
);

export default layout;
