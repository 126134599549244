import React from 'react';
import PropTypes from 'prop-types';

import './Input.scss';

const Input = ({ label, ...props }) => {
  return (
    <>
      <label
        htmlFor={props.id}
        className={'finsa-label finsa-label--' + props.type}
      >
        <input
          placeholder={props.placeholder}
          required={props.required}
          type={props.type}
          {...props}
          className={'finsa-input finsa-input--' + props.type}
        />
        {props.type === 'checkbox' ? (
          <span className="finsa-input--checkbox__checkmark" />
        ) : null}
        {label}
      </label>
    </>
  );
};

export default Input;

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func
};
