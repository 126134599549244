import React, { useContext, useEffect } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { ContactFormContext } from '../ContactFormContext';

import Form from '@ui/Form/Form';
import Input from '@ui/Input/Input';

import './FinsaStep.scss';

const ProductsStep = ({ products }) => {

  useEffect(() => {
    scrollTo('#form');
  }, []);

  const { dispatch } = useContext(ContactFormContext);

  const availableProducts = ['UCITS', 'AIF', 'Swiss funds', 'Others'];

  const handleChange = (product) => {
    const isSelected = products.includes(product);
    let payload = products;

    if (isSelected) {
      payload = products.filter((c) => c !== product);
    } else {
      payload = [...products, product];
    }

    dispatch({
      type: 'PRODUCTS_CHANGE',
      payload,
    });
  };

  return (
    <div className="finsa-step">
      <h2 className="finsa-step__headline">
        What type of financial instruments do you plan on offering to investors in Switzerland? (several answers possible)
      </h2>

      <Form>
        {availableProducts.map((product) => (
          <div key={product}>
            <Input
              type="checkbox"
              label={product}
              name={product}
              id={product}
              value={product}
              onChange={() => handleChange(product)}
              checked={products.includes(product)}
            />
          </div>
        ))}
      </Form>
    </div>
  );
};

export default ProductsStep;
