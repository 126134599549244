import React from 'react';
import Logo from '@svg/logo.inline.svg';

import './Logo.scss';

const logo = () => (
  <div className="finsa-logo">
    <Logo />
  </div>
);

export default logo;
