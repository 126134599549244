import React, { useState, useReducer } from 'react';
import axios from 'axios';

import creds from '../../../api/config';

import { renderToStaticMarkup } from "react-dom/server";

import Button from '@components/Button/Button';
import Loading from '@components/Loading/Loading';
import MyEmail from '@components/EmailTemplate/EmailTemplate';

import IdentityStep from './Steps/IdentityStep';
import LocationStep from './Steps/LocationStep';
import SupervisionStep from './Steps/SupervisionStep';
import OmbudsmanStep from './Steps/OmbudsmanStep';
import GdprObligeStep from './Steps/GdprObligeStep';
import GdprStep from './Steps/GdprStep';
import FinancialServicesStep from './Steps/FinancialServicesStep';
import ProductsStep from './Steps/ProductsStep';
import TargetsStep from './Steps/TargetsStep';
import UserInfoStep from './Steps/UserInfoStep';

import { ContactFormContext } from './ContactFormContext';

import './ContactForm.scss';

let stepCheck = false;

const useFormProgress = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const goForward = (check, st) => {
    setCurrentStep(currentStep + 1);

    if (check.gdproblige === 'No') {
      stepCheck = true;
      setCurrentStep(currentStep + 2);
    } 

    if (check.gdproblige === 'Yes') {
      stepCheck = false;
    }

    if (st.location === 'European Union' && check.hasOwnProperty('ombudsman')) {
      setCurrentStep(currentStep + 3);
    }
  };

  const goBack = (check, st) => {
    if (check.hasOwnProperty('financialService') && stepCheck) {
      setCurrentStep(currentStep - 2)
    } else if (st.location === 'European Union' && check.hasOwnProperty('financialService')) {
      setCurrentStep(currentStep - 3);
    }
    else {
      setCurrentStep(currentStep - 1);
    }
  };

  return [currentStep, goForward, goBack];
};

//** INITIAL STATE  */
const initialState = {
  identity: '',
  location: '',
  supervision: '',
  ombudsman: '',
  gdproblige: '',
  gdpr: '',
  financialService: '',
  products: [],
  targets: [],
  salutation: '',
  name: '',
  surname: '',
  position: '',
  company: '',
  email: '',
  agrees: [],
  isSubmitLoading: false,
  isSubmissionReceived: false,
};

const formReducer = (state, action) => {
  switch (action.type) {
    case 'IDENTITY_CHANGE':
      return { ...state, identity: action.payload };
    case 'LOCATION_CHANGE':
      return { ...state, location: action.payload };
    case 'SUPERVISION_CHANGE':
      return { ...state, supervision: action.payload };
    case 'OMBUDSMAN_CHANGE':
      return { ...state, ombudsman: action.payload };
    case 'GDPROBLIGE_CHANGE':
      return { ...state, gdproblige: action.payload };
    case 'GDPR_CHANGE':
      return { ...state, gdpr: action.payload };
    case 'FINANCIAL_SERVICES_CHANGE':
      return { ...state, financialService: action.payload };
    case 'PRODUCTS_CHANGE':
      return { ...state, products: action.payload };
    case 'TARGETS_CHANGE':
      return { ...state, targets: action.payload };
    case 'SALUTATION_CHANGE':
      return { ...state, salutation: action.payload };
    case 'NAME_CHANGE':
      return { ...state, name: action.payload };
    case 'SURNAME_CHANGE':
      return { ...state, surname: action.payload };
    case 'POSITION_CHANGE':
      return { ...state, position: action.payload };
    case 'COMPANY_CHANGE':
      return { ...state, company: action.payload };
    case 'EMAIL_CHANGE':
      return { ...state, email: action.payload };
    case 'AGREE_CHANGE':
      return { ...state, agrees: action.payload };
    case 'RECAPTCHA_CHANGE':
      return { ...state, response: action.payload };
    case 'SUBMIT':
      return { ...state, isSubmitLoading: true };
    case 'SUBMISSION_RECEIVED':
      return { ...state, isSubmitLoading: false, isSubmissionReceived: true };
    default:
      throw new Error();
  }
};

const ContactForm = () => {

  const [state, dispatch] = useReducer(formReducer, initialState);
  const {
    identity,
    location,
    supervision,
    ombudsman,
    gdproblige,
    gdpr,
    financialService,
    products,
    targets,
    salutation,
    name,
    surname,
    position,
    company,
    email,
    agrees,
    response,
  } = state;

  const steps = [
    <IdentityStep {...{ identity }} />,
    <LocationStep {...{ location }} />,
    <SupervisionStep {...{ supervision }} />,
    <OmbudsmanStep {...{ ombudsman }} />,
    <GdprObligeStep {...{ gdproblige }} />,
    <GdprStep {...{ gdpr }} />,
    <FinancialServicesStep {...{ financialService }} />,
    <ProductsStep {...{ products }} />,
    <TargetsStep {...{ targets }} />,
    <UserInfoStep
      {...{ salutation, name, surname, position, company, email, agrees }}
    />,
  ];

  const [currentStep, goForward, goBack] = useFormProgress();
  const isFirst = currentStep === 0;
  const isLast = currentStep === steps.length - 1;

  const validateEmail = (email) => {
    //eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const isEmpty = ({ ...obj }) => {
    if (obj.hasOwnProperty('salutation')) {
      delete obj.salutation;
    }
    if (obj.hasOwnProperty('position')) {
      delete obj.position;
    }

    let email = false;
    let validate = false;

    for (var key in obj) {
      if (key === 'email') {
        email = validateEmail(obj[key]);

        if (Array.isArray(obj[key])) {
          validate = obj[key].length > 0 && email ? false : true;
        }
      }

      if (Array.isArray(obj[key])) {
        validate = obj[key].length > 0 ? false : true;
      }
    }

    const result = !Object.values(obj).every(
      (element) => element !== '' && !validate
    );

    if (isLast && !response) {
      return true;
    } else if (isLast && response) {
      return result && response;
    } else {
      return result;
    }
  };

  const handleSubmit = () => {
    dispatch({ type: 'SUBMIT' });

    const messageHtml = renderToStaticMarkup(<MyEmail
      email={email}
      salutation={salutation}
      name={name}
      surname={surname}
      position={position}
      company={company}
      identity={identity}
      location={location}
      supervision={supervision}
      gdproblige={gdproblige}
      gdpr={gdpr}
      ombudsman={ombudsman}
      financialService={financialService}
      products={products}
      targets={targets}
      agrees={agrees}
    />);

    
    axios({
      method: 'POST',
      url: creds.URL,
      data: {
        name: name,
        email: email,
        messageHtml: messageHtml,
        response: response,
        state: state,
      },
    }).then((response) => {
      if (response.data.msg === 'success') {
        dispatch({ type: 'SUBMISSION_RECEIVED' });
        console.log('Email send awesome'); //! TODO
      } else if (response.data.msg === 'fail') {
        console.log('Ooop something is wrong'); //! TODO
      }
    });
  };

  if (state.isSubmitLoading) {
    document.body.style.overflow = 'hidden';
    return <Loading />;
  }

  if (state.isSubmissionReceived) {
    document.body.style.overflow = 'visible';
    return (
      <div className="finsa-questionnaire">
        <h2 className="finsa-questionnaire__info">
          Thanks for your submission!
        </h2>
      </div>
    );
  }

  return (
    <ContactFormContext.Provider value={{ dispatch }}>
      <div className="finsa-questionnaire">
        {steps[currentStep]}
        <div className="finsa-questionnaire__buttons">
          <Button disabled={isFirst} click={() => {goBack(steps[currentStep].props, state)}} text="Go Back" />
          <Button
            disabled={isEmpty(steps[currentStep].props)}
            type="submit"
            click={(e) => {
              e.preventDefault();
              if (isLast) {
                handleSubmit();
              } else {
                goForward(steps[currentStep].props, state);
              }
            }}
            text={isLast ? 'Submit' : 'Next'}
          />
        </div>
        <div className="finsa-questionnaire__steps">
          Step {currentStep + 1} of {steps.length}
        </div>
      </div>
    </ContactFormContext.Provider>
  );
};

export default ContactForm;
