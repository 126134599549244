import React from 'react';

import './Section.scss';
import List from '@components/List/List';

const section = (props) => {
  return (
    <div
      className="finsa-section"
      style={{ backgroundColor: props.color, height: props.height, color: props.textColor }}
      id={props.id}
    >
      {props.headline ? (
        <h2 style={{ color: props.headlineColor }}>{props.headline}</h2>
      ) : null}
      {props.list ? (
        <List textColor={props.textColor} list={props.list} />
      ) : null}
      {props.children}
    </div>
  );
};

export default section;
