import React, { useContext, useEffect } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { ContactFormContext } from '../ContactFormContext';

import Form from '@ui/Form/Form';
import Input from '@ui/Input/Input';

import './FinsaStep.scss';

const GdprObligeStep = ({ gdproblige }) => {

  useEffect(() => {
    scrollTo('#form');
  }, []);

  const { dispatch } = useContext(ContactFormContext);

  const availableGdprOblige = ['No', 'Yes'];

  return (
    <div className="finsa-step">
      <h2 className="finsa-step__headline">
      As you may know, the EU GDPR obliges all companies outside the EU to designate a representative in the EU if they process personal data of EU residents and do not maintain an establishment in the EU. In the view of the above, do you fall under the scope of this obligation?
      </h2>
      <Form>
        {availableGdprOblige.map(id => (
          <Input
            label={id}
            key={id}
            type="radio"
            id={id}
            name="gdproblige"
            value={id}
            checked={gdproblige === id}
            onChange={e =>
              dispatch({ type: 'GDPROBLIGE_CHANGE', payload: e.target.value })
            }
          />
        ))}
      </Form>
    </div>
  );
};

export default GdprObligeStep;
