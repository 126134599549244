import React from 'react';

import Link from '@components/Link/Link';

import './Pano.scss';

const pano = () => {
  return (
    <div className="finsa-pano">
      <h2 className="finsa-pano__headline">Acolin FinSA questionnaire</h2>
      <div className="finsa-pano__gdpr">
        <p className="finsa-pano__gdpr__text">
          Be compliant with the Swiss laws and receive a personal offer for
          FinSA
        </p>
      </div>
      <nav role="navigation" className="finsa-pano__navigation">
        <Link section="rapa" name="Swiss representative" />
        <Link section="ombudsman" name="Ombudsman" />
        <Link section="car" name="Client Advisor Register" />
        <Link section="insurance" name="Insurance" />
      </nav>
    </div>
  );
};

export default pano;
