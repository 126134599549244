import React from 'react';

import './SocialLinks.scss';

const socialLinks = () => {
  return (
    <div className="finsa-social">
      <a
        href="https://www.linkedin.com/company/acolin-fund-services-ag/"
        target="_blank"
        rel="noreferrer"
        className="finsa-social__link"
      >
        LinkedIn
      </a>
      <a
        href="https://www.xing.com/companies/acolinfundservicesag"
        target="_blank"
        rel="noreferrer"
        className="finsa-social__link"
      >
        Xing
      </a>
      <a
        href="https://twitter.com/acolin_group"
        target="_blank"
        rel="noreferrer"
        className="finsa-social__link"
      >
        Twitter
      </a>
    </div>
  );
};

export default socialLinks;
