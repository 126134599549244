import React, { useContext, useEffect } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { ContactFormContext } from '../ContactFormContext';

import './FinsaStep.scss';

import Form from '@ui/Form/Form';
import Input from '@ui/Input/Input';
import List from '@components/List/List';

const FinancialServicesStep = ({ financialService }) => {
  
  useEffect(() => {
    scrollTo('#form');
  }, []);
  
  const { dispatch } = useContext(ContactFormContext);

  const availableFinacialServices = [
    "No, we don't provide any financial services",
    'Yes, we do provide financial services',
  ];

  const list = [
    'Acquisition or disposal of financial instruments',
    'Receipt and transmission of orders in relation to financial instruments',
    'Administration of financial instruments (portfolio management)',
    'Provision of personal recommendations on transactions with financial instruments (investment advice)',
    'Granting of loans to finance transactions with financial instruments',
  ];

  return (
    <div className="finsa-step">
      <h2 className="finsa-step__headline">
        Do you provide financial services within the meaning of art. 3 finsa in Switzerland?
      </h2>
      <Form>
        {availableFinacialServices.map((id) => (
          <Input
            label={id}
            key={id}
            type="radio"
            id={id}
            name="financialService"
            value={id}
            checked={financialService === id}
            onChange={(e) =>
              dispatch({
                type: 'FINANCIAL_SERVICES_CHANGE',
                payload: e.target.value,
              })
            }
          />
        ))}
      </Form>
      <h3>
        Financial services are any of the following activities carried out for
        clients:
      </h3>
      <List list={list} />
    </div>
  );
};

export default FinancialServicesStep;
