import React, { useContext, useEffect } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { ContactFormContext } from '../ContactFormContext';

import Form from '@ui/Form/Form';
import Input from '@ui/Input/Input';

import './FinsaStep.scss';

const GdprStep = ({ gdpr }) => {

  useEffect(() => {
    scrollTo('#form');
  }, []);

  const { dispatch } = useContext(ContactFormContext);

  const availableGdpr = ['No', 'Yes'];

  return (
    <div className="finsa-step">
      <h2 className="finsa-step__headline">
        Have you designated in writing a representative in the European Union for the
        GDPR?
      </h2>
      <Form>
        {availableGdpr.map(id => (
          <Input
            label={id}
            key={id}
            type="radio"
            id={id}
            name="gdpr"
            value={id}
            checked={gdpr === id}
            onChange={e =>
              dispatch({ type: 'GDPR_CHANGE', payload: e.target.value })
            }
          />
        ))}
      </Form>
    </div>
  );
};

export default GdprStep;
