import React from "react";

import scrollTo from "gatsby-plugin-smoothscroll";
import { Helmet } from "react-helmet";

import "@styles/main.scss";

import Insurance from "@svg/insurance.inline.svg";

import Layout from "@layouts/default/default.js";

import Pano from "@components/Pano/Pano";
import Section from "@components/Section/Section";
import ContactForm from "@components/ContactForm/ContactForm";

const colors = {
  white: "#ffffff",
  lightBackground: "#f2f2f2",
  primary: "#202e42",
  tertiary: "#d89f5a",
  text: "#172b4d"
}

const App = () => (
  <>
    <Helmet>
      <title>Acolin Finsa</title>
    </Helmet>  
    <Layout>
      <Pano />
      <Section color={colors.white}>
        <p>
          Please fill in the questionnaire <strong>"All I need for FinSA"</strong>{" "}
          (estimated time 5 minutes) to define what kind of service you need to be
          compliant with the new Swiss laws and to receive your personal offer for
          FinSA.
        </p>
      </Section>
      <Section color={colors.white} id="form">
        <ContactForm />
      </Section>
      <Section
        color={colors.primary}
        headlineColor={colors.tertiary}
        textColor={colors.white}
        headline="Representative"
        id="rapa"
        list={[
          "Since August 2006 Acolin Fund Services AG has held a Swiss Financial Market Supervisory Authority FINMA license to act as a representative of foreign collective investment schemes.",
          "We ensure that all legal and marketing documents are at any time updated, compliant with Swiss regulations, and if requested published on the relevant electronic publication platform.​",
          "We act as your local point of contact for Swiss investors and FINMA.",
          "We are responsible for making the offering of foreign funds to qualified and non-qualified investors in Switzerland compliant.",
        ]}
      >
        <p>
          Find out by filling in the following{"  "}
          <button
            className="finsa-general-link"
            onClick={() => scrollTo("#form")}
          >
            questionnaire.
          </button>{" "}
        </p>
        <button
          className="finsa-back-to-top"
          onClick={() => scrollTo(".finsa-header")}
        >
          Back to top
        </button>
      </Section>
      <Section
        headline="Ombudsman"
        headlineColor={colors.primary}
        textColor={colors.text}
        id="ombudsman"
        color={colors.lightBackground}
        list={[
          `Acolin chooses strategic partners for the Swiss ombudsman for financial service providers. <br/>
          The Federal Department of Finance announced our partners as Swiss ombudsman for financial service providers.
          Acolin offers a tailor-made support solution in order to be compliant with this obligation in Switzerland (filing all necessary documents, negotiating the agreement, managing the whole registration on the client’s behalf). For all our existing clients, Acolin is happy to announce that our representation services fees will cover the fees of the affiliation to our partner.
          <ul>
            <li>The Swiss ombudsman acts as an independent and neutral mediator in disputes. A Swiss ombudsman is an alternative to expensive legal proceedings.</li>
          </ul>`,
          "Do you need to be affiliated to a Swiss ombudsman?",
        ]}
      >
        <p>
          Find out by filling in the following{" "}
          <button
            className="finsa-general-link"
            onClick={() => scrollTo("#form")}
          >
            questionnaire.
          </button>{" "}
        </p>
        <button
          className="finsa-back-to-top"
          onClick={() => scrollTo(".finsa-header")}
        >
          Back to top
        </button>
      </Section>
      <Section
        headline="Client Advisor Register"
        headlineColor={colors.tertiary}
        textColor={colors.white}
        color={colors.primary}
        id="car"
        list={[
          `Acolin proposes <a target="_blank" href="https://arif.ch/en/">ARIF</a>, Association Romande des Intermédiaires Financiers, as a solution to both existing and new customers of Acolin who must enrol on the Client Advisers’ Register. All existing or new customers of Acolin will be offered a tailor-made support solution so as to comply with this new Swiss requirement as soon as possible.`,
          `Acolin offers a tailor-made support solution in order to comply with this new obligation in Switzerland and takes care of the entire registration process on the client’s behalf (including filing all necessary documents and negotiating relevant agreements).
          <ul>
            <li>The approval of the first registration office by FINMA will be on July, 20th 2020. Consequently, the entry into a register of advisers must be done before January, 19th 2021.</li>
            <li>Client advisers are entered in the register of advisers if they prove that they:</li>
            <li>Have sufficient knowledge of the FinSA rules of conduct and of the expertise required to perform their work <a href="https://www.admin.ch/opc/en/classified-compilation/20152661/index.html" target="_blank">(Article 6)</a>.</li>
            <li>Have taken out professional indemnity insurance or that equivalent collateral exists and</li>
            <li>Are either themselves affiliated to an ombudsman's office <a target="_blank" href="https://www.admin.ch/opc/en/classified-compilation/20152661/index.html">(Article 74)</a> in their capacity as a financial service provider or the financial service provider for which they work is affiliated to an ombudsman's office.</li>
          </ul>`,
          "Do you need to be entered in the Client Advisor Register?",
        ]}
      >
        <p>
          Find out by filling in the following{" "}
          <button
            className="finsa-general-link"
            onClick={() => scrollTo("#form")}
          >
            questionnaire.
          </button>{" "}
        </p>
        <div className="finsa-section__wrapper">
          {/* <img src={regservices} className="finsa-section__wrapper__img" /> */}
        </div>
        <button
          className="finsa-back-to-top"
          onClick={() => scrollTo(".finsa-header")}
        >
          Back to top
        </button>
      </Section>
      <Section
        headline="Insurance"
        headlineColor={colors.primary}
        textColor={colors.primary}
        color={colors.lightBackground}
        id="insurance"
        list={[
          `Acolin proposes <a target="_blank" href="https://www.libertyspecialtymarkets.com" />Liberty Specialty Markets</a> as professional liability insurance in order to enter into a register of advisers. All existing or new customers of Acolin will be offered a tailor-made support solution in order to be compliant with this new obligation in Switzerland as soon as possible.
          <ul>
            <li>If you need to be entered in a Client Advisor Register, you must:</li>
            <li>Either have taken out professional liability insurance,</li>
            <li>or prove that equivalent collateral exists.</li>
          </ul>`,
          "Do you need to take out a professional liability insurance?",
        ]}
      >
        <p>
          Find out by filling in the following{" "}
          <button
            className="finsa-general-link"
            onClick={() => scrollTo("#form")}
          >
            questionnaire.
          </button>{" "}
        </p>
        <div className="finsa-section__wrapper">
          <Insurance className="finsa-section__wrapper__img" />
        </div>
        <button
          className="finsa-back-to-top"
          onClick={() => scrollTo(".finsa-header")}
        >
          Back to top
        </button>
      </Section>
    </Layout>
  </>
);

export default App;