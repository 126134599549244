import React from 'react';

import parse from 'html-react-parser';

import './List.scss';

const list = (props) => (
  <ul className="finsa-list">
    {props.list.map((item) => (
      <li
        style={{ color: props.textColor }}
        className="finsa-list__item"
        key={item}
      >
        {parse(item)}
      </li>
    ))}
  </ul>
);

export default list;
