import React from 'react';

import SocialLinks from '@components/SocialLinks/SocialLinks';

import './Footer.scss';

const currentYear = new Date().getFullYear();

const Footer = () => (
  <footer className="finsa-footer" id="footer">
    <div className="finsa-footer__brand">Copyright &copy; Acolin {currentYear}</div>
    <SocialLinks />
  </footer>
);

export default Footer;
