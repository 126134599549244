import React, { useContext, useEffect } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { Helmet } from 'react-helmet';
import Recaptcha from 'react-recaptcha';

import { ContactFormContext } from '../ContactFormContext';
import Form from '@ui/Form/Form';
import Input from '@ui/Input/Input';

import './FinsaStep.scss';

const infosAvailable = [
  {
    name: 'Salutation',
    change: 'SALUTATION_CHANGE',
    type: 'text',
    placeholder: 'Mr./Mrs.',
  },
  {
    name: 'Name',
    change: 'NAME_CHANGE',
    type: 'text',
    placeholder: 'Write your name',
  },
  {
    name: 'Surname',
    change: 'SURNAME_CHANGE',
    type: 'text',
    placeholder: 'Write your surname',
  },
  {
    name: 'Position',
    change: 'POSITION_CHANGE',
    type: 'text',
    placeholder: 'Write the position you have in your company',
  },
  {
    name: 'Company',
    change: 'COMPANY_CHANGE',
    type: 'text',
    placeholder: 'What is your company name?',
  },
  {
    name: 'Email',
    change: 'EMAIL_CHANGE',
    type: 'email',
    placeholder: 'Enter a valid email address',
  },
];

const UserInfoStep = ({
  salutation,
  name,
  surname,
  position,
  company,
  email,
  agrees,
  response,
}) => {

  useEffect(() => {
    scrollTo('#form');
  }, []);
  
  const { dispatch } = useContext(ContactFormContext);

  const values = [salutation, name, surname, position, company, email];

  const availableAgree = [
    'By entering your email address and ticking the case, you agree to receive news from Acolin with the above mentioned content in accordance with our data Privacy Policy.',
  ];

  const verifyCallback = (response) => {
    if (response) {
      dispatch({
        type: 'RECAPTCHA_CHANGE',
        payload: response,
      });
    }
  };

  const resetCapctha = () => {
    response = false;
    dispatch({
      type: 'RECAPTCHA_CHANGE',
      payload: response,
    });
  };

  const handleChange = (agree) => {
    const isSelected = agrees.includes(agree);
    let payload = agrees;

    if (isSelected) {
      payload = agrees.filter((c) => c !== agree);
    } else {
      payload = [...agrees, agree];
    }

    dispatch({
      type: 'AGREE_CHANGE',
      payload,
    });
  };

  return (
    <div className="finsa-step">
      <h2 className="finsa-step__headline">
        Enter Your Contact Information To Receive Your Tailor-made Package On
        Order To Be Fit For Finsa
      </h2>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js`}
          async
          defer
        ></script>
      </Helmet>
      <Form>
        {infosAvailable.map((info, i) => (
          <Input
            required="required"
            key={info.name}
            type={info.type}
            label={info.name}
            name={info.name}
            placeholder={info.placeholder}
            onChange={(e) =>
              dispatch({ type: info.change, payload: e.target.value })
            }
            value={values[i]}
          />
        ))}
        <br />
        <br />
        {availableAgree.map((agree) => (
          <Input
            key="agree"
            type="checkbox"
            label={agree}
            name={agree}
            id={agree}
            value={agree}
            onChange={() => handleChange(agree)}
            checked={agrees.includes(agree)}
          />
        ))}
      </Form>
      <Recaptcha
        sitekey="6LeoJOcUAAAAAJIuglXaukwTSzHu6dQJqhMnLSD0"
        render="explicit"
        verifyCallback={verifyCallback}
        onloadCallback={resetCapctha}
      />
    </div>
  );
};

export default UserInfoStep;
