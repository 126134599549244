import React from 'react';

import './Link.scss';

import scrollTo from 'gatsby-plugin-smoothscroll';

const Link = (props) => (
  <button
    className="finsa-link__button"
    onClick={() => scrollTo('#' + props.section)}
  >
    {props.name}
  </button>
);

export default Link;
