import React, { useContext, useEffect } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { ContactFormContext } from '../ContactFormContext';

import Form from '@ui/Form/Form';
import Input from '@ui/Input/Input';

import './FinsaStep.scss';

const TargetsStep = ({ targets }) => {

  useEffect(() => {
    scrollTo('#form');
  }, []);

  const { dispatch } = useContext(ContactFormContext);

  const retailTargets = [
    'Retail clients under discretionary mandate/advisory agreements',
    'High-net-worth retail clients',
    'Private investment structures (family offices) without professional treasury operations created for high-net-worth retail clients',
    'Professional clients that did an opting-in to be considered as retail investors',
    'Other clients that are not professional clients',
  ];

  const uniqueTarget = ['High-net-worth retail clients and private investment structures (family offices) without professional treasury operations created for them who have declared that they wish to be treated as professional clients (opting out) [HNWI according to art 5 para 1 and FinSA]'];

  const professionalTargets = [
    'Public entities with professional treasury operations',
    'Occupational pension schemes with and occupational pensions institutions providing professional treasury operations',
    'Companies with professional treasury operations',
    'Large companies',
    'Private investment structures (family offices) with professional treasury operations',
  ];

  const institutionalTargets = [
    'Swiss supervised financial intermediaries (e.g. banks, portfolio managers, managment companies)',
    'Swiss supervised insurance companies',
    'Foreign financial intermediaries and insurance companies subject to prudential supervision in their home country',
    'Central banks',
    'National and supernational public entities with professional treasury operations'
  ]

  const handleChange = (target) => {
    const isSelected = targets.includes(target);
    let payload = targets;

    if (isSelected) {
      payload = targets.filter((c) => c !== target);
    } else {
      payload = [...targets, target];
    }

    dispatch({
      type: 'TARGETS_CHANGE',
      payload,
    });
  };

  return (
    <div className="finsa-step">
      <h2 className="finsa-step__headline">
        What kind of investors do you plan to target in switzerland? (several
        answers possible, please focus on the end investors)
      </h2>
      <Form>
        <p>Retail</p>
        {retailTargets.map((target) => (
          <Input
            key={target}
            type="checkbox"
            label={target}
            name={target}
            id={target}
            value={target}
            onChange={() => handleChange(target)}
            checked={targets.includes(target)}
          />
        ))}
        <hr style={{marginTop: "60px"}} />
        {uniqueTarget.map((target) => (
          <Input
            key={target}
            type="checkbox"
            label={target}
            name={target}
            id={target}
            value={target}
            onChange={() => handleChange(target)}
            checked={targets.includes(target)}
          />
        ))}
        <hr style={{marginBottom: "60px"}}/>
        <p>Professional</p>
        {professionalTargets.map((target) => (
          <Input
            key={target}
            type="checkbox"
            label={target}
            name={target}
            id={target}
            value={target}
            onChange={() => handleChange(target)}
            checked={targets.includes(target)}
          />
        ))}
        <p>Institutional</p>
        {institutionalTargets.map((target) => (
          <Input
            key={target}
            type="checkbox"
            label={target}
            name={target}
            id={target}
            value={target}
            onChange={() => handleChange(target)}
            checked={targets.includes(target)}
          />
        ))}
      </Form>
      <h3>
        Retail clients or private investment structures (family offices) without professional treasury
        operations, that did opting-out to be considered as professional clients
      </h3>
      <p>You are considered as a HNWI according to:</p>
      <ul>
        <li className="finsa-list__item">
          Art.5.1 FinSA High-net-worth retail clients and private investment
          structures created for them may declare that they wish to be treated
          as professional clients (opting out).
        </li>
        <li className="finsa-list__item">
          Art 5.2 FinSA Any person who can credibly declare that they satisfy at
          least one of both following criteria:
          <ul>
            <li>
              on the basis of training, education and professional experience or
              on the basis of comparable experience in the financial sector,
              they possess the necessary knowledge to understand the risks
              associated with the investments and have at their disposal assets
              of at least CHF 500,000; or
            </li>
            <li>
              they have at their disposal assets of at least CHF 2 million.
            </li>
          </ul>
        </li>
      </ul>
      <h3> Opting-in: client segmentation jump (down):</h3>
      <ul>
        <li className="finsa-list__item">
          Institutional client and you want to be treated as a professional
          client
        </li>
        <li className="finsa-list__item">
          Professional client and you want to be treated as a retail client
        </li>
      </ul>
      <h3>Opting-out: client segmentation jump (up):</h3>
      <ul>
        <li className="finsa-list__item">
          Professional client and you want to be treated as an institutional
          client
        </li>
        <li className="finsa-list__item">
          Retail client and you want to be treated as a professional client
        </li>
        <li className="finsa-list__item">
          Retail client and you want to be treated as an institutional client
        </li>
      </ul>
    </div>
  );
};

export default TargetsStep;
