import React, { useContext } from 'react';

import { ContactFormContext } from '../ContactFormContext';

import Form from '@ui/Form/Form';
import Input from '@ui/Input/Input';

import './FinsaStep.scss';

const IdentityStep = ({ identity }) => {
  
  const { dispatch } = useContext(ContactFormContext);

  const availableIdentities = [
    'Bank',
    'Family office (with professional treasury operations)',
    'Family office (without professional treasury operations)',
    'Foreign distributor / third party marketer',
    'Former Swiss distributor with a license limited to qualified investors which became void',
    'Former Swiss distributor with a license for both qualified and non-qualified investors which became void',
    'Fund company / FCP / Unit trust',
    'Fund management company',
    'Insurance company',
    'Investment advisor',
    'Manager of collective assets',
    'Pension fund',
    'Portfolio manager',
    'Securities firm',
    'Trustee',
  ];

  return (
    <div className="finsa-step">
      <h2 className="finsa-step__headline">Who are you?</h2>
      <Form>
        {availableIdentities.map((id) => (
          <Input
            label={id}
            key={id}
            type="radio"
            id={id}
            name="identity"
            value={id}
            checked={identity === id}
            onChange={(e) =>
              dispatch({ type: 'IDENTITY_CHANGE', payload: e.target.value })
            }
          />
        ))}
      </Form>
    </div>
  );
};

export default IdentityStep;
