import React, { useContext, useEffect } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { ContactFormContext } from '../ContactFormContext';

import './FinsaStep.scss';

import Form from '@ui/Form/Form';
import Input from '@ui/Input/Input';

const OmbudsmanStep = ({ ombudsman }) => {

  useEffect(() => {
    scrollTo('#form');
  }, []);

  const { dispatch } = useContext(ContactFormContext);

  const availableOmbudsman = ['No', 'Yes'];

  return (
    <div className="finsa-step">
      <h2 className="finsa-step__headline">
        Are you already affiliated to a swiss ombudsman?
      </h2>
      <Form>
        {availableOmbudsman.map((id) => (
          <Input
            label={id}
            key={id}
            type="radio"
            id={id}
            name="ombudsman"
            value={id}
            checked={ombudsman === id}
            onChange={(e) =>
              dispatch({ type: 'OMBUDSMAN_CHANGE', payload: e.target.value })
            }
          />
        ))}
      </Form>
    </div>
  );
};

export default OmbudsmanStep;
