import React from 'react';
import PropTypes from 'prop-types';

import './Form.scss';

const Form = ({ children, ...props }) => {
  return (
    <form {...props} className="finsa-form">
      {children}
    </form>
  );
};

export default Form;

Form.propTypes = {
  children: PropTypes.node
};
