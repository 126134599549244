import React, { useContext, useEffect } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { ContactFormContext } from '../ContactFormContext';

import './FinsaStep.scss';

import Form from '@ui/Form/Form';
import Input from '@ui/Input/Input';

const SupervisionStep = ({ supervision }) => {

  useEffect(() => {
    scrollTo('#form');
  }, []);
  
  const { dispatch } = useContext(ContactFormContext);

  const availableSupervisions = ['No', 'Yes'];

  return (
    <div className="finsa-step">
      <h2 className="finsa-step__headline">
        Are you supervised in your home country?
      </h2>
      <Form>
        {availableSupervisions.map(id => (
          <Input
            label={id}
            key={id}
            type="radio"
            id={id}
            name="supervision"
            value={id}
            checked={supervision === id}
            onChange={e =>
              dispatch({ type: 'SUPERVISION_CHANGE', payload: e.target.value })
            }
          />
        ))}
      </Form>
    </div>
  );
};

export default SupervisionStep;
